import { useQueryClient } from "@tanstack/react-query"

import {
  OwnerType,
  TIntegrationHomeLinksFilter,
  TIntegrationId,
} from "src/data/integrations/types/integrationTypes"

export const integrationKeys = {
  /** Use to target all integration data in the cache */
  all: () => {
    return ["integrations"] as const
  },

  /** Use to target integration data connected to a specific organization with filters */
  integrations: (props: {
    orgId: string
    filters?: { name?: string; configured?: boolean }
  }) => {
    if (props.filters) {
      return [...integrationKeys.all(), props.orgId, props.filters] as const
    }
    return [...integrationKeys.all(), props.orgId] as const
  },

  /** Use to target all data for a single integration */
  integration: (props: { orgId: string; integration: TIntegrationId }) => {
    return [...integrationKeys.integrations(props), props.integration] as const
  },

  /** Use to target multiaccount credentials in a single integration */
  multiAccountCredentials: ({
    orgId,
    integration,
    filters,
  }: {
    orgId: string
    integration: TIntegrationId
    filters?: { limit: number; offset: number }
  }) => {
    if (filters) {
      return [
        ...integrationKeys.integration({ orgId, integration }),
        "multiAccountCredentials",
        { filters },
      ] as const
    }
    return [
      ...integrationKeys.integration({ orgId, integration }),
      "multiAccountCredentials",
    ] as const
  },

  /** Use to target all links for a single integration with filters */
  links: (
    props: {
      orgId: string
      integration: TIntegrationId
      ownerType?: OwnerType
    },
    filters?: string
  ) => {
    return [...integrationKeys.integration(props), "link", { filters }] as const
  },

  /** Use to target links for multiple integrations */
  multipleIntegrationLinks: (props: {
    orgId: string
    integrationIds: TIntegrationId[]
  }) => {
    return [
      ...integrationKeys.all(),
      props.orgId,
      "multipleIntegrationLinks",
      props.integrationIds.sort(),
    ] as const
  },

  /** Use to target organization homes and their links */
  integrationHomeLinks: (
    props: {
      integration: TIntegrationId
      orgId: string
      ownerType: OwnerType
    },
    filters?: TIntegrationHomeLinksFilter
  ) => {
    if (filters) {
      return [
        ...integrationKeys.integration(props),
        "integrationHomeLinks",
        { filters },
      ] as const
    }
    return [
      ...integrationKeys.integration(props),
      "integrationHomeLinks",
    ] as const
  },

  /** Use to target a specific link to an integration */
  link: (props: {
    orgId: string
    integration: TIntegrationId
    linkId: string
  }) => {
    return [
      ...integrationKeys.integration(props),
      "link",
      props.linkId,
    ] as const
  },

  /** Use to target all entities in an integration */
  entities: (props: { orgId: string; integration: TIntegrationId }) => {
    return [...integrationKeys.integration(props), "entity"] as const
  },

  /** Use to target a specific entity in an integration */
  entity: (props: {
    orgId: string
    integration: TIntegrationId
    entityId: string
  }) => {
    return [
      ...integrationKeys.integration(props),
      "entity",
      props.entityId,
    ] as const
  },
}

export function useIntegrationCache() {
  const queryClient = useQueryClient()

  function invalidateMultiaccountCredentials({
    orgId,
    integration,
    filters,
  }: {
    orgId: string
    integration: TIntegrationId
    filters?: { limit: number; offset: number }
  }) {
    queryClient.invalidateQueries(
      integrationKeys.multiAccountCredentials({ orgId, integration, filters })
    )
  }

  return {
    invalidateMultiaccountCredentials,
  }
}
