import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import axios, { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { isExternalLinkIntegration } from "src/data/integrations/logic/integrations"
import { integrationKeys } from "src/data/integrations/queries/integrationQueryCache"
import {
  OwnerType,
  TIntegration,
  TIntegrationId,
  TIntegrationResponse,
  TIntegrationType,
  TPostGuestsCSVAuthError,
  TPostGuestsCSVResponse,
  TPostGuestsCSVRowError,
} from "src/data/integrations/types/integrationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function usePostGuestsCSV() {
  async function postGuestsCSV({ orgId, file }: { orgId: string; file: File }) {
    const text = await file.text()
    const response = await minutApiHttpClient.post<TPostGuestsCSVResponse>(
      `${API_DEFAULT}/organizations/${orgId}/csv/guests`,
      text,
      {
        headers: {
          "Content-Type": "text/csv",
        },
      }
    )
    return response.data
  }

  return useMutation<
    TPostGuestsCSVResponse,
    AxiosError<TPostGuestsCSVRowError | TPostGuestsCSVAuthError>,
    { orgId: string; file: File }
  >(postGuestsCSV, {})
}

export const GuestCSVErrors = {
  isRowErrors: function (
    err: AxiosError<TPostGuestsCSVRowError | TPostGuestsCSVAuthError>
  ): err is AxiosError<TPostGuestsCSVRowError> {
    return axios.isAxiosError(err) && err.response?.status === 400
  },
  isAuthError: function (
    err: AxiosError<TPostGuestsCSVRowError | TPostGuestsCSVAuthError>
  ): err is AxiosError<TPostGuestsCSVAuthError> {
    return axios.isAxiosError(err) && err.response?.status === 403
  },
}

export function useFetchIntegrations(
  {
    orgId,
    filters,
  }: {
    orgId: string
    filters?: {
      name?: string
      configured?: boolean
      integrationType?: TIntegrationType[]
      includeAirbnbValidation?: boolean
      includeExternalIntegrations?: boolean
    }
  },
  options?: UseQueryOptions<
    TIntegrationResponse,
    AxiosError,
    TIntegrationResponse,
    ReturnType<typeof integrationKeys.integrations>
  >
) {
  async function fetchIntegrations() {
    const response = await minutApiHttpClient.get<{
      integrations: TIntegration[]
    }>(`${API_DEFAULT}/integrations/`, {
      params: {
        owner_id: orgId,
        owner_type: OwnerType.ORGANIZATION,
        name: filters?.name,
        type: filters?.integrationType,
      },
    })
    return { ...response.data, userIntegrations: false }
  }

  return useQuery({
    queryKey: integrationKeys.integrations({ orgId, filters }),
    queryFn: fetchIntegrations,
    staleTime: 500,
    keepPreviousData: true,
    select: (data) => {
      let filteredIntegrations = filterIntegrations({
        integrations: data.integrations,
        includeAirbnbValidation: filters?.includeAirbnbValidation ?? false,
        includeExternalIntegrations:
          filters?.includeExternalIntegrations ?? false,
      })

      if (filters?.configured === true) {
        filteredIntegrations = filteredIntegrations.filter((i) => i.configured)
      } else if (filters?.configured === false) {
        filteredIntegrations = filteredIntegrations.filter((i) => !i.configured)
      }

      return { ...data, integrations: filteredIntegrations }
    },
    ...options,
  })
}

function filterIntegrations({
  integrations,
  includeAirbnbValidation,
  includeExternalIntegrations,
}: {
  integrations: TIntegration[]
  includeAirbnbValidation: boolean
  includeExternalIntegrations: boolean
}): TIntegration[] {
  const filteredExternalIntegrations = includeExternalIntegrations
    ? integrations
    : integrations.filter((i) => !isExternalLinkIntegration(i))

  if (includeAirbnbValidation) {
    return filteredExternalIntegrations
  }

  /**
   * airbnb_validate_account is just used for validating that someone has a
   * proper airbnb account, connecting to it will just make the user eligible
   * for the airbnb campaign offer.
   */
  const actualIntegrations = filteredExternalIntegrations.filter(
    (i) => i.integration_identifier !== "airbnb_validate_account"
  )

  return actualIntegrations
}

/** Disconnect a user integration. */
export function useIntegrationDelete() {
  const queryClient = useQueryClient()

  /** https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1{integration_identifier}/delete */
  async function deleteIntegration({
    integration,
    orgId,
    ownerType,
  }: {
    integration: TIntegrationId
    orgId: string
    ownerType: OwnerType
  }) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/integrations/${integration}`,
      {
        params: {
          owner_id: orgId,
          owner_type: ownerType,
        },
      }
    )
    return response.data
  }

  return useMutation(deleteIntegration, {
    onSuccess: (_, { orgId, ownerType }) => {
      const cacheKey =
        ownerType === OwnerType.USER
          ? integrationKeys.all()
          : integrationKeys.integrations({ orgId })
      return queryClient.invalidateQueries(cacheKey)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    onError: (err: AxiosError<any>) => {
      throw err
    },
  })
}
